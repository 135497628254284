.elli {
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 0;
  top: 7px;
  transform-origin: top center;
  transition: 800ms ease-out;
  width: 50px;
  height: 50px;
}

.elli-big {
  height: 100px;
  width: 100px;
}

.counter {
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 0px;
  font-size: 12px;
  justify-content: center;
  left: calc(50% + 16px);
  min-height: 20px;
  min-width: 20px;
  padding: 0 4px;
  position: absolute;
  top: 8px;
  transition: transform 800ms ease-out;
}

.counter-border {
  background-color: #025879;
  border-radius: 10px;
  border: solid #fff 1px;
}

.counter-big {
  font-weight: 600;
  transform: translateX(32px);
}
