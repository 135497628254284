::-webkit-scrollbar {
  width: 0.4em;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  outline: 1px solid slategrey;
}

.MuiPickersTimePickerToolbar-hourMinuteLabel .MuiTypography-h2 {
  color: #fff;
  font-size: 2.125em;
}

.MuiPickersClockNumber-clockNumberSelected.MuiTypography-body1 {
  color: #fff;
}

blockquote.gmail_quote {
  display: none;
}

.gmail_attr {
  display: none;
}

#root body {
  background: transparent;
}
